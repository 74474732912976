import {
  Link as GatsbyLink,
  useI18next,
  Trans,
} from "gatsby-plugin-react-i18next";
import { LeadSection, Button, Icon, Link } from "@lleed-partners/factor";
import { ArrowRight32 } from "@carbon/icons-react";

import { Layout } from "../layouts";

const NotFoundPage = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <LeadSection
        padTop
        title="404"
        subtitle={
          <>
            {t("We could not find what you are looking for.")}
            <br />
            <br />
            {t(
              "This page might have been deleted or may not be available in your region."
            )}
            <br />
            <Trans t={t}>
              If you believe this is an error,{" "}
              <Link as={GatsbyLink} to="/contact/">
                please contact us.
              </Link>
            </Trans>
          </>
        }
        cta={
          <>
            <Button
              as={GatsbyLink}
              to="/"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
            >
              {t("Get back home")}
            </Button>
            <Button
              as={GatsbyLink}
              to="/sitemap/"
              intent="ghost"
              rightIcon={<Icon icon={<ArrowRight32 />} />}
            >
              {t("Browse the sitemap")}
            </Button>{" "}
          </>
        }
      />
    </Layout>
  );
};

export default NotFoundPage;
